import * as React from 'react';
import { TextSub1 } from '../typography/typography';
import styles from './alt_button_link.module.css';
import { Anchor } from '../anchor/anchor';

type AltButtonProps = {
  href: string;
  label: string;
};

export const AltButtonLink = ({ href, label }: AltButtonProps) => (
  <Anchor href={href} className={styles.link}>
    <TextSub1>{label}</TextSub1>
  </Anchor>
);

import * as React from 'react';
import { PageTemplateProps } from 'shared/types/pages';
import { Section } from 'ui/components/molecules/section/section';
import { BannerRTE } from 'ui/components/molecules/banner/banner_rte';
import { Container } from 'ui/components/organisms/container/container';
import { ImageBlockProps, PageRTEProps, RTESectionsBlocks, WYSIWYGBlockProps } from 'templates/rte';
import { WYSIWYG } from 'ui/components/molecules/wysiwyg/wysiwyg';
import { Picture } from 'ui/components/atoms/picture/picture';
import { TextSmall } from 'ui/components/atoms/typography/typography';
import styles from './rte.module.css';
import { createRestrictedModal } from 'ui/components/molecules/modal/modal';
import { Main } from 'ui/components/organisms/main/main';
import { JsonLD } from 'ui/components/molecules/json_ld/json_ld';

function WYSIWYGBlock({ wysiwyg }: WYSIWYGBlockProps) {
  return <WYSIWYG html={wysiwyg} />;
}

function ImageBlock({ image, caption }: ImageBlockProps) {
  return (
    <div className={styles.imageBlock}>
      <Picture type="fluid" fluid={image.localFile.childImageSharp.fluid} placeholder="285x190" />
      {caption && (
        <TextSmall tagName="div" className={styles.caption}>
          {caption}
        </TextSmall>
      )}
    </div>
  );
}

const RTEBlocks = (block: RTESectionsBlocks) => {
  switch (block.fieldGroupName) {
    case 'page_Acftemplaterte_Rte_sections_Section_Imageblock':
      return ImageBlock;
    case 'page_Acftemplaterte_Rte_sections_Section_Wysiwygblock':
      return WYSIWYGBlock;
    default:
      // eslint-disable-next-line
      console.error('No block found');
      return undefined as any;
  }
};

export const RTE = ({ data }: PageTemplateProps<PageRTEProps>) => {
  const { banner, sections } = data.wpPage.acfTemplateRTE.rte;
  const { primaryCta, enablePrimaryCta, secondaryCta, enableSecondaryCta } = banner;
  const { breadcrumbs } = data.wpPage.seo;
  const { RestrictedModal, store } = createRestrictedModal({
    acfSharedPage: data.wpPage.acfSharedPage,
  });
  return (
    <React.Fragment>
      <JsonLD
        title={data.wpPage.title}
        acfSharedPage={data.wpPage.acfSharedPage}
        breadcrumbs={breadcrumbs}
      />
      <Main isBlurred={store.isVisible}>
        <BannerRTE
          heading={banner.heading}
          description={banner.description}
          image={banner.image}
          breadcrumbs={{ breadcrumbs }}
          primaryCta={enablePrimaryCta && primaryCta ? primaryCta : undefined}
          secondaryCta={enableSecondaryCta && secondaryCta ? secondaryCta : undefined}
        />

        {sections.map((sectionBlock, i) => {
          const { section } = sectionBlock;
          return (
            <Section key={i} backgroundColor={i % 2 !== 0 ? 'white' : 'grey'}>
              <Container>
                {section.map((block, j) => {
                  const Block = RTEBlocks(block);
                  return (
                    <div key={j} className={styles.block}>
                      <Block {...block} />
                    </div>
                  );
                })}
              </Container>
            </Section>
          );
        })}
      </Main>
      <RestrictedModal />
    </React.Fragment>
  );
};

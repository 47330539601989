import * as React from 'react';
import { Container } from 'ui/components/organisms/container/container';
import { TextPriority2, Spacing } from 'ui/components/atoms/typography/typography';
import { Row, Col } from 'ui/components/organisms/grid/grid';
import styles from './banner_rte.module.css';
import { Picture } from 'ui/components/atoms/picture/picture';
import { WPImageFluid } from 'shared/types/wp_localImage';
import { Breadcrumbs, BreadcrumbsProps } from '../breadcrumbs/breadcrumbs';
import { WatermarksBanner } from 'ui/components/atoms/watermarks/watermarksBanner';
import { WYSIWYG } from '../wysiwyg/wysiwyg';
import { PrimaryButtonLink } from 'ui/components/atoms/link/link';
import { AltButtonLink } from 'ui/components/atoms/button/alt_button_link';

type BannerRTEProps = {
  heading: string;
  description: string;
  image: null | WPImageFluid;
  breadcrumbs: BreadcrumbsProps;
  primaryCta?: {
    label: string;
    href: string;
  };
  secondaryCta?: {
    label: string;
    href: string;
  };
};

export const BannerRTE = ({
  image,
  description,
  heading,
  breadcrumbs,
  primaryCta,
  secondaryCta,
}: BannerRTEProps) => {
  return (
    <div className={styles.banner}>
      <div className={styles.bannerInner}>
        {image ? (
          <WatermarksBanner
            variant="O"
            aspectRatio={1 / image.localFile.childImageSharp.fluid.aspectRatio}
          />
        ) : null}
        <Container>
          <Row middle="md">
            <Col sm={12} md={6} lg={5} lgOffset={1}>
              <div className={styles.textContent}>
                <Breadcrumbs {...breadcrumbs} />
                <TextPriority2 tagName="h1" bottomSpacing={Spacing.SPACING_16}>
                  {heading}
                </TextPriority2>
                <WYSIWYG html={description} />
                <div className={styles.cta}>
                  {primaryCta && (
                    <div>
                      <PrimaryButtonLink href={primaryCta.href}>
                        {primaryCta.label}
                      </PrimaryButtonLink>
                    </div>
                  )}
                  {secondaryCta && (
                    <div className={styles.secondaryCta}>
                      <AltButtonLink href={secondaryCta.href} label={secondaryCta.label} />
                    </div>
                  )}
                </div>
              </div>
            </Col>
            <Col className={styles.imageColumn} sm={12} md={6} lg={5}>
              <Picture
                type="fluid"
                fluid={image?.localFile.childImageSharp.fluid}
                className={styles.image}
                placeholder="285x190"
              />
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

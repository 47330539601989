import { graphql } from 'gatsby';
import { WPImageFluid } from 'shared/types/wp_localImage';
import { RTE } from 'ui/components/templates/rte/rte';

export const query = graphql`
  query RTEPageQuery($id: String!) {
    wpPage(id: { eq: $id }) {
      ...PageContent
      acfTemplateRTE {
        rte {
          banner {
            heading
            description
            image {
              localFile {
                ...FluidImageFull
              }
            }
            enablePrimaryCta
            primaryCta {
              href
              label
            }
            enableSecondaryCta
            secondaryCta {
              href
              label
            }
          }
          sections {
            section {
              ... on WpPage_Acftemplaterte_Rte_sections_Section_Imageblock {
                fieldGroupName
                image {
                  localFile {
                    ...FluidImageFull
                  }
                }
                caption
              }
              ... on WpPage_Acftemplaterte_Rte_sections_Section_Wysiwygblock {
                fieldGroupName
                wysiwyg
              }
            }
          }
        }
      }
    }
    wpGlobal: wp {
      ...WpGlobal
    }
  }
`;

export type WYSIWYGBlockProps = {
  fieldGroupName: 'page_Acftemplaterte_Rte_sections_Section_Wysiwygblock';
  wysiwyg: string;
};

export type ImageBlockProps = {
  fieldGroupName: 'page_Acftemplaterte_Rte_sections_Section_Imageblock';
  image: WPImageFluid;
  caption: null | string;
};

export type RTESectionsBlocks = WYSIWYGBlockProps | ImageBlockProps;

export type PageRTEProps = {
  acfTemplateRTE: {
    rte: {
      banner: {
        heading: string;
        description: string;
        image: null | WPImageFluid;
        enablePrimaryCta: null | boolean;
        primaryCta: null | {
          label: string;
          href: string;
        };
        enableSecondaryCta: null | boolean;
        secondaryCta: null | {
          label: string;
          href: string;
        };
      };
      sections: {
        section: RTESectionsBlocks[];
      }[];
    };
  };
};

export default RTE;

import { Link } from 'gatsby';
import * as React from 'react';
import classNames from 'classnames';

interface Props {
  children: React.ReactNode;
  className?: string;
}

/**
 * If external link, open in new window.
 */
export const Anchor = ({ children, href, className }: Props & { href: string }) => {
  const externalLinkRegex = new RegExp('^(?:#|(?:[a-z]+:)?//|mailto:)', 'i');
  const isExternalLink = externalLinkRegex.test(href);

  if (!isExternalLink) {
    return (
      <Link to={href} className={classNames(className)}>
        {children}
      </Link>
    );
  }
  return (
    <a href={href} target="_blank" rel="noreferrer" className={classNames(className)}>
      {children}
    </a>
  );
};
